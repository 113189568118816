import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { useFetchWrapper as Api } from '../../utils';
import { STATEMENT, STATEMENT_LEGACY } from '../../components/constants/api-paths';
import { HTTP_STATUS } from '../../components/constants';
import { STATEMENT_LABEL } from '../../pages/statements/components/merchant-statement/constants/statement-status';
import DasSnackbar from '../../components/das-snackbar/DasSnackbar';
import { STATUS } from '../../pages/merchants/constants/merchant';
import useLegacy from '../../hooks/use-legacy/useLegacy';
// import { convertAdjustmentAmountStringToNumber } from '../../utils/helper';

// createAsyncThunk
export const getMerchantStatementTable: any = createAsyncThunk(
  'statements/getMerchantStatementTable',
  async apiPath => {
    let data = await Api().get(apiPath);
    return data;
  },
);

export const editMerchantStatement: any = createAsyncThunk(
  'statements/editMerchantStatement',
  async ({ payload, handleDrawerClose }: any) => {
    const legacy = useLegacy();
    // const { tableData, ID, handleDrawerClose } = values;
    // delete values['handleDrawerClose'];
    const approveStatementApiPath = legacy ? STATEMENT_LEGACY.APPROVE_STATEMENT : STATEMENT.APPROVE_STATEMENT;
    // const currentRow = tableData?.find((item: any) => item.ID === ID);
    // delete values['ID'];
    // const changeInStatus = values?.STATUS && currentRow.SettlementStatus !== values.STATUS;
    // convertAdjustmentAmountStringToNumber(currentAdjustmentAmount.AdjustmentsAmount) === values.AdjustmentsAmount;
    // delete values['tableData'];
    // const payload = changeInStatus ? {
    //   ID, data: { STATUS: values.STATUS }
    // } : {
    //   ID, data: values,
    // };
    let data = await Api().post(`${approveStatementApiPath}`, payload);
    if ([HTTP_STATUS.CREATED, HTTP_STATUS.OK].includes(data?.statusCode)) {
      if ([HTTP_STATUS.OK].includes(data?.statusCode)) {
        // let index = tableData.findIndex((item: any) => item.ID === ID);
        // let finalData = tableData;
        // if (index !== -1) {
        //   let itemWithID = tableData[index];
        //   itemWithID = {
        //     ...itemWithID,
        //     FixedDepositHeldYTDAmount: values.FixedDepositHeldYTDAmount
        //       ? values.FixedDepositHeldYTDAmount
        //       : itemWithID.FixedDepositHeldYTDAmount,
        //     AdjustmentsAmount: values.AdjustmentsAmount
        //       ? values.AdjustmentsAmount
        //       : itemWithID.AdjustmentsAmount,
        //     SettlementStatus: values.STATUS
        //       ? values.STATUS
        //       : itemWithID.SettlementStatus,
        //     WiredStatus: values.Wired_Status
        //       ? values.Wired_Status
        //       : itemWithID.WiredStatus,
        //   };

        //   finalData = [
        //     ...tableData.slice(0, index),
        //     itemWithID,
        //     ...tableData.slice(index + 1),
        //   ];
        // }
        DasSnackbar.success(data?.message);
        handleDrawerClose();
        // temporary fix need to resolve later with new approach
        window.location.reload();
        return data;
      }
      else {
        return data;
      }
    }
  },
);
export const approveStatement: any = createAsyncThunk(
  'chargeback/approveStatement',
  async (values: any) => {
    const legacy = useLegacy();
    const { setStatementID, statementID } = values;
    try {
      const approveStatementApiPath = legacy ? STATEMENT_LEGACY.APPROVE_STATEMENT : STATEMENT.APPROVE_STATEMENT;
      const result = await Api().post(approveStatementApiPath, {
        data: { STATUS: STATUS.APPROVED },
        ID: statementID.toString(),
      });
      const { tableData } = values;
      if ([HTTP_STATUS.OK].includes(result?.statusCode)) {
        let index = tableData.findIndex((item: any) => {
          return item.ID == statementID[0];
        });
        let finalData = tableData;
        if (index !== -1) {
          let itemWithDasmid = tableData[index];
          itemWithDasmid = {
            ...itemWithDasmid,
            SettlementStatus: STATUS.APPROVED,
          };

          finalData = [
            ...tableData.slice(0, index),
            itemWithDasmid,
            ...tableData.slice(index + 1),
          ];
        }
        DasSnackbar.success(result?.message);
        setStatementID();
        //temporary fix
        window.location.reload();
        return finalData;
      }
      return result;
    } catch (e) {
      console.error(e);
    }
  },
);

// createSlice
export const merchantStatementTableSlice = createSlice({
  name: 'merchantStatementTable',
  initialState: {
    merchantStatementTable: [],
    count: 0,
    loading: false,
    error: null,
    // refreshList:false
  },
  reducers: {
    addNewRow: (state: any, { payload }) => {
      state.merchantStatementTable = [payload, ...state.merchantStatementTable];
    },
  },
  extraReducers: builder => {
    builder.addCase(getMerchantStatementTable.pending, state => {
      state.loading = true;
      // state.refreshList=false;
    });
    builder.addCase(getMerchantStatementTable.fulfilled, (state, action) => {
      let newRecords = action?.payload?.data?.records?.map((item: any) => {
        let updatedValues = item;
        STATEMENT_LABEL.forEach((data: any) => {
          delete Object.assign(updatedValues, updatedValues, {
            [data['customLabel']]: updatedValues[data['defaultLabel']],
          })[data['defaultLabel']];
        });
        return updatedValues;
      });
      state.loading = false;
      state.merchantStatementTable = newRecords;
      state.count = action?.payload?.data?.records[0]?.TotalCount;
      // state.refreshList=false;
    });
    builder.addCase(getMerchantStatementTable.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
      // state.refreshList=false;
    });
    //Edit merchant statement
    builder.addCase(editMerchantStatement.pending, state => {
      state.loading = true;
    });
    builder.addCase(editMerchantStatement.fulfilled, (state) => {
      // state.merchantStatementTable = payload;
      state.loading = false;

    });
    builder.addCase(editMerchantStatement.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(approveStatement.pending, state => {
      state.loading = true;
    });
    builder.addCase(approveStatement.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.merchantStatementTable = payload;
    });
    builder.addCase(approveStatement.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});
export const { addNewRow } = merchantStatementTableSlice.actions;
export const loadingRows = (state: any) =>
  state?.merchantStatementTable?.loading;
export const riskRows = (state: any) =>
  state?.merchantStatementTable?.merchantStatementTable;
export const rowsCount = (state: any) => state?.merchantStatementTable?.count;
// export const refreshStatementList = (state: any)=>state?.merchantStatementTable?.refreshList;
export default merchantStatementTableSlice.reducer;
