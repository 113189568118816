import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { authSlice } from './features/auth';
import { loaderSlice } from './features/loader';
import { drawerSlice } from './features/drawer';
import { dialogSlice } from './features/dialog';
import { detailSlice } from './features/details';
import { merchantSlice } from './features/merchant';
import { filterSlice } from './features/filter';
import { merchantDrawerSlice } from './features/merchant-drawer';
import { transactionSlice } from './features/transaction-link';
import { actionButtonSlice } from './features/action-buttons';
import { transactionTableSlice } from './features/transaction-table';
import { merchantTableSlice } from './features/merchant-table';
import { riskTableSlice } from './features/risk-table';
import { csvDateSlice } from './features/csvDate';
import { drawerTypeSlice } from './features/drawer-type';
import {
  persistReducer,
  // FLUSH,
  // REHYDRATE,
  // PAUSE,
  // PERSIST,
  // PURGE,
  // REGISTER,
} from 'redux-persist';
import localStorage from 'reduxjs-toolkit-persist/lib/storage/index';
import { productRiskTableSlice } from './features/product-risk-table';
import { payByLinkTableSlice } from './features/pbl';
import { pblCartTableSlice } from './features/add-pbl';
import { productsListSlice } from './features/product-table';
import { acquirersTableSlice } from './features/acquirers';
import { acquirerDetailsSlice } from './features/acquirer-details';
import { merchantStatementTableSlice } from './features/merchant-statement-details';
import { disputeManagementTableSlice } from './features/dispute-management-table.redux';
import { disputeDrawerSlice } from './features/chargeback-details';
import { dasConfirmationDialogBoxSlice } from './features/das-confirmation-dialog-box';
import { partnerTableSlice } from './features/partner';
import { productType } from './features/contact-us';
import { onboardingSlice } from './features/onboarding';
// import { onboardingSlice } from './features/onboarding';
import { publicHolidayTableSlice } from './features/public-holiday';
import { salesLeadSlice } from './features/sales-lead';
import { titleSlice } from './features/app-title';
import subscriptionsSlice from './features/subscriptions-table';
import { configSlice } from './features/gateway-config';
// import sessionStorage from "reduxjs-toolkit-persist/lib/storage/session";

let persistConfig = {
  key: 'root',
  storage: localStorage,
  // blacklist: ["loader", "login", "transactionTable"],
  whitelist: ['auth', 'config'
    // 'onboarding'
  ],
};

const rootReducer = combineReducers({
  title: titleSlice.reducer,
  loader: loaderSlice.reducer,
  config: configSlice.reducer,
  auth: authSlice.reducer,
  drawer: drawerSlice.reducer,
  dialog: dialogSlice.reducer,
  details: detailSlice.reducer,
  merchant: merchantSlice.reducer,
  filter: filterSlice.reducer,
  merchantDrawer: merchantDrawerSlice.reducer,
  transaction: transactionSlice.reducer,
  actionButton: actionButtonSlice.reducer,
  transactionTable: transactionTableSlice.reducer,
  merchantTable: merchantTableSlice.reducer,
  riskTable: riskTableSlice.reducer,
  csvDate: csvDateSlice.reducer,
  drawerType: drawerTypeSlice.reducer,
  productRiskTable: productRiskTableSlice.reducer,
  payByLinkTable: payByLinkTableSlice.reducer,
  pblCartTable: pblCartTableSlice.reducer,
  productsList: productsListSlice.reducer,
  acquirersTable: acquirersTableSlice.reducer,
  acquirerDetails: acquirerDetailsSlice.reducer,
  merchantStatementTable: merchantStatementTableSlice.reducer,
  disputeManagementTable: disputeManagementTableSlice.reducer,
  disputeDrawer: disputeDrawerSlice.reducer,
  dasDialogBox: dasConfirmationDialogBoxSlice.reducer,
  partnersTable: partnerTableSlice.reducer,
  productTypeDetails: productType.reducer,
  uploadedFileData: productType.reducer,
  supportTicketInfoQuery: productType.reducer,
  onboarding: onboardingSlice.reducer,
  publicHoliday: publicHolidayTableSlice.reducer,
  salesLead: salesLeadSlice.reducer,
  subscriptionsTable: subscriptionsSlice.reducer
});

let persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware => [
    ...getDefaultMiddleware({
      serializableCheck: false,
    }),
  ],
});

export default store;
